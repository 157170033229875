import axios from 'axios';
import { backendAddress } from './addressConfig';
import messages_lv from '../translations/lv.json';
import messages_en from '../translations/en.json';

export const messages = {
  lv: messages_lv,
  en: messages_en,
};

export const axiosHeaders = () => {
  return {
    Authorization: 'JWT ' + localStorage.getItem('access_token'),
    'Content-Type': 'application/json',
    accept: 'application/json',
  };
};

export const checkCurrentAccessToken = (token: string) => {
  if (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    const jsonObject = JSON.parse(jsonPayload);
    return jsonObject.exp > Math.floor(Date.now() / 1000);
  }
  return !!token;
};

export const refreshAccessToken = async (token: string) => {
  if (checkCurrentAccessToken(token)) {
    await axios
      .post(backendAddress + '/api/auth/refresh_tk/', {
        refresh: token,
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('access_token', response.data.access);
          localStorage.setItem('refresh_token', response.data.refresh);
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });
  } else {
    return false;
  }
};

export const EMPTY_USER = {
  name: '',
  surname: '',
  user_type: 0,
};

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
