import { createContext } from 'react';

export const Context = createContext();
export const LoggedContext = createContext();
export const ProjectListContext = createContext();
export const ObjectListContext = createContext();
export const DpAppListContext = createContext();
export const HandDpListContext = createContext();
export const DpTelAssignedListContext = createContext();
export const DpTelUnassignedListContext = createContext();
export const DsListContext = createContext();
export const MeterListContext = createContext();
export const UserListContext = createContext();
export const UiListContext = createContext();
//export const UiSectionListContext = createContext(); - endpoint list_section_ui no longer fetched
export const SurveyListContext = createContext();
export const MainUiContext = createContext();
export const SectionModalContext = createContext();
export const LimitsContext = createContext();
export const WarningsContext = createContext();
export const LanguageContext = createContext();
export const ReportListContext = createContext();
export const SadalsListContext = createContext();
export const SadalsMeterListContext = createContext();
export const MqttConfigListContext = createContext();
export const WarnTypesContext = createContext();
export const MqttUserListContext = createContext();
export const CurrentMainUiContext = createContext();
export const UnsavedChangesContext = createContext();
export const HasUnreadWarningsContext = createContext();
