import React from 'react';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { COLORS } from '../../../config/design';
import { useTheme } from '@mui/material';

const CircularIndeterminate = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: 'flex',
      }}>
      <CircularProgress
        variant={'determinate'}
        sx={{
          color: COLORS.transparent,
          marginLeft: theme.spacing(2),
        }}
        size={24}
        thickness={3}
        value={100}
      />
      <CircularProgress
        variant={'indeterminate'}
        disableShrink
        sx={{
          color: COLORS.transparent,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          marginLeft: theme.spacing(2),
        }}
        size={24}
        thickness={3}
      />
    </div>
  );
};

export default CircularIndeterminate;
