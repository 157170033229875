export const getSessionItemWithExpiration = (key: string) => {
  const data = sessionStorage.getItem(key);
  if (data) {
    const parsedItem = JSON.parse(data);
    const currentTimestamp = Date.now();
    if (parsedItem.expiration && parsedItem.expiration > currentTimestamp) {
      return JSON.parse(parsedItem.value);
    } else {
      sessionStorage.removeItem(key);
    }
  }
  return null;
};

export const setSessionItemWithExpiration = (
  key: string,
  value: string,
  expirationInMinutes: number,
) => {
  const expirationMilliseconds = expirationInMinutes * 60 * 1000;
  const expirationTimestamp = Date.now() + expirationMilliseconds;
  const item = {
    value: value,
    expiration: expirationTimestamp,
  };
  sessionStorage.setItem(key, JSON.stringify(item));
};
