// imports from react
import React, { Suspense } from 'react';
// imports from other components
import CircularLoader from '../UI/CircularLoader';

const LazyLoadWrapper = ({ component: Component, ...rest }) => {
  return (
    <>
      <Suspense fallback={<CircularLoader />}>
        <Component {...rest} />
      </Suspense>
    </>
  );
};

export default LazyLoadWrapper;
