// imports from react
import React, { lazy, useEffect, useState } from 'react';
// imports from other libraries
import axios from 'axios';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
// imports from constants, helpers, etc.
import { backendAddress } from '../config/addressConfig';
import { axiosHeaders, checkCurrentAccessToken, EMPTY_USER, messages } from '../config/constants';
// imports from contexts
import {
  Context,
  LoggedContext,
  MainUiContext,
  SectionModalContext,
  LanguageContext,
  HasUnreadWarningsContext,
} from './contextValues';
// imports from other components
import LazyLoadWrapper from './components/HOC/LazyLoadWrapper';
// imports from other components using lazy loading
const AdminPage = lazy(() => import('./components/adminPage'));
const LoginPage = lazy(() => import('./components/LoginPage/LoginPage'));
const NavBarSideBar = lazy(() => import('./components/navBar/navbarSidebar'));
const SurveyPage = lazy(() => import('./components/surveyPage'));
const ErrorPage = lazy(() => import('./components/ErrorPage/ErrorPage'));
const SurveyPageSimple = lazy(() => import('./components/SurveyPageSimple'));

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import {
  getSessionItemWithExpiration,
  setSessionItemWithExpiration,
} from '../functions/helpersCache';

const theme = createTheme();

const RenderApp = () => {
  const [mainUiName, setMainUiName] = useState('');
  const [mainUi, setMainUi] = useState(null);
  const [configuration, setConfiguration] = useState([]);
  const [bigScreen] = useState(window.innerWidth >= 960);
  const [logged, setLogged] = useState(
    localStorage.getItem('access_token') !== null || localStorage.getItem('refresh_token') !== null,
  );
  const [currentUser, setCurrentUser] = useState({ ...EMPTY_USER });
  const [hasUnreadWarning, setHasUnreadWarning] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState(navigator.language.split(/[-_]/)[0] ?? 'lv');

  const handleChangeLanguage = (newLang) => {
    setLanguage(newLang);
  };

  useEffect(() => {
    let newLang;
    if (currentUser.language !== undefined) {
      switch (currentUser.language) {
        case 'Latviešu':
          newLang = 'lv';
          break;
        case 'Angļu':
          newLang = 'en';
          break;
        default:
          newLang = 'lv';
      }
      setLanguage(newLang);
    }
  }, [currentUser]);

  const handleLogin = async (login, password) => {
    try {
      const response = await axios.post(`${backendAddress}/api/auth/obtain_tk/`, {
        email: login,
        password: password,
      });

      if (response.status === 200) {
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);

        const userResult = await axios.get(`${backendAddress}/api/users/current/`, {
          headers: axiosHeaders(),
        });

        if (userResult.status === 200) {
          await handleUserLogin(userResult.data);
        } else {
          handleLoginError();
        }
      } else {
        handleLoginError();
      }
    } catch (error) {
      handleLoginError();
    }
  };

  const handleUserLogin = async (userData) => {
    try {
      if (userData.main_ui) {
        const mainUiResult = await axios.get(
          `${backendAddress}/api/ui/main_ui/${userData.main_ui}/`,
          {
            headers: axiosHeaders(),
          },
        );

        if (mainUiResult.status === 200) {
          const mainUiData = mainUiResult.data[0];
          setMainUiName(mainUiData.ui_name);
          setMainUi(mainUiData);
          setConfiguration(mainUiData.sections.sort((a, b) => a.section_nr - b.section_nr));
        }
      }

      const hasUnreadWarning = await getUnreadWarnings(userData.email);
      // setCurrentUser({ ...userData, has_unread_warning: hasUnreadWarning });
      setHasUnreadWarning(hasUnreadWarning);
      setCurrentUser(userData);
      setLogged(true);
      setError(false);
    } catch (error) {
      handleLoginError();
    } finally {
      setLoading(false);
    }
  };

  const getUnreadWarnings = async (email) => {
    const cachedWarning = getSessionItemWithExpiration(`${email}has_unread_warning`);

    if (cachedWarning !== null) {
      return cachedWarning;
    }

    const warningResult = await axios.get(`${backendAddress}/api/warnings/has-unread-warnings/`, {
      headers: axiosHeaders(),
    });

    if (warningResult.status === 200) {
      const hasUnreadWarnings = warningResult.data.has_unread_warnings;
      setSessionItemWithExpiration(`${email}has_unread_warning`, hasUnreadWarnings, 25);
      return hasUnreadWarnings;
    }

    return false;
  };

  const handleLoginError = () => {
    setError(true);
    setLogged(false);
    setCurrentUser({ ...EMPTY_USER });
    setLoading(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setConfiguration([]);
    setLogged(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${backendAddress}/api/users/current/`, {
          headers: axiosHeaders(),
        });

        if (result.status === 200) {
          await handleUserLogin(result.data);
        } else {
          handleLoginError();
        }
      } catch (error) {
        handleLoginError();
      }
    };

    const fetchRefresh = async (token) => {
      try {
        const result = await axios.post(`${backendAddress}/api/auth/refresh_tk/`, {
          refresh: token,
        });

        if (result.status === 200) {
          localStorage.setItem('access_token', result.data.access);
          localStorage.setItem('refresh_token', result.data.refresh);
          await fetchData();
        }
      } catch (error) {
        handleLoginError();
      }
    };

    if (checkCurrentAccessToken(localStorage.getItem('access_token'))) {
      fetchData();
    } else if (checkCurrentAccessToken(localStorage.getItem('refresh_token'))) {
      fetchRefresh(localStorage.getItem('refresh_token'));
    }
  }, []);

  const updateMainUi = async () => {
    if (logged) {
      const resultData = await axios.get(
        `${backendAddress}/api/ui/main_ui/${currentUser.main_ui}/`,
        {
          headers: axiosHeaders(),
        },
      );

      if (resultData.status === 200) {
        const mainUiData = resultData.data[0];
        setMainUiName(mainUiData.ui_name);
        setMainUi(mainUiData);
        setConfiguration(mainUiData.sections.sort((a, b) => a.section_nr - b.section_nr));
      }
    }
  };

  const updateCurrentUser = async () => {
    if (logged) {
      const result = await axios.get(`${backendAddress}/api/users/current/`, {
        headers: axiosHeaders(),
      });

      if (result.status === 200) {
        await handleUserLogin(result.data);
      }
    }
  };

  const updateHasUnreadWarning = async () => {
    if (logged) {
      const resultData = await axios.get(`${backendAddress}/api/warnings/has-unread-warnings/`, {
        headers: axiosHeaders(),
      });
      if (resultData.status === 200) {
        setSessionItemWithExpiration(
          `${currentUser.email}has_unread_warning`,
          resultData.data.has_unread_warnings,
          25,
        );
        setHasUnreadWarning(resultData.data.has_unread_warnings);
      }
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LoggedContext.Provider value={[logged, setLogged]}>
          <Context.Provider
            value={[currentUser, setCurrentUser, updateCurrentUser, updateHasUnreadWarning]}>
            <HasUnreadWarningsContext.Provider value={[hasUnreadWarning, setHasUnreadWarning]}>
              <MainUiContext.Provider value={[mainUi, updateMainUi]}>
                <LanguageContext.Provider value={{ language, handleChangeLanguage }}>
                  <IntlProvider locale={language} messages={messages[language]}>
                    <SectionModalContext.Provider value={[open, setOpen]}>
                      <BrowserRouter>
                        <Routes>
                          <Route
                            key={'login-page-route'}
                            path={'/login'}
                            element={
                              <LazyLoadWrapper
                                component={LoginPage}
                                {...{ logged, currentUser, error, handleLogin }}
                              />
                            }
                          />
                          {currentUser.user_type && currentUser.user_type === 4 && (
                            <Route
                              key={'survey-simple-route'}
                              path={'/user-page/*'}
                              element={
                                <LazyLoadWrapper
                                  component={SurveyPageSimple}
                                  {...{ logged, currentUser, handleLogout, mainUiName }}
                                />
                              }
                            />
                          )}
                          {currentUser.user_type && currentUser.user_type < 4 && (
                            <Route
                              key={'admin-page-route'}
                              path={'/admin-page/*'}
                              element={
                                <LazyLoadWrapper
                                  component={AdminPage}
                                  {...{ logged, currentUser, handleLogout, mainUiName }}
                                />
                              }
                            />
                          )}
                          <Route
                            key={'survey-page-route'}
                            path={'/survey/*'}
                            element={<LazyLoadWrapper component={SurveyPage} {...{ bigScreen }} />}
                          />
                          <Route
                            key={'error-route'}
                            path={'/error-page'}
                            element={
                              <LazyLoadWrapper
                                component={ErrorPage}
                                {...{
                                  configuration,
                                  logged,
                                  currentUser,
                                  handleLogout,
                                  loading,
                                  mainUiName,
                                }}
                              />
                            }
                          />
                          <Route
                            key={'content-page-route'}
                            path={'*'}
                            element={
                              <LazyLoadWrapper
                                component={NavBarSideBar}
                                {...{
                                  configuration,
                                  logged,
                                  currentUser,
                                  handleLogout,
                                  loading,
                                  mainUiName,
                                }}
                              />
                            }
                          />
                        </Routes>
                      </BrowserRouter>
                    </SectionModalContext.Provider>
                  </IntlProvider>
                </LanguageContext.Provider>
              </MainUiContext.Provider>
            </HasUnreadWarningsContext.Provider>
          </Context.Provider>
        </LoggedContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default RenderApp;
