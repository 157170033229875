export const COLORS = {
  main_green_1: '#004c45',
  main_green_2: '#00615c',
  main_blue_1: '#dcf4f2',
  main_blue_2: '#edf8f8',
  main_yellow_1: '#d8af19',
  main_yellow_2: '#f8d03f',
  main_gray_1: '#333',
  white: '#FFF',
  transparent: 'transparent',
  new_main_blue_1: '#00538A',
  new_main_blue_2: 'rgba(0, 83, 138, 0.75)',
  new_main_white_0: '#FFFFFF',
  new_main_white_1: '#ECEFF1',
  new_main_white_2: '#B0BEC5',
  new_button_green_1: 'rgba(85, 130, 90, 1)',
  new_button_green_2: 'rgba(85, 130, 90, 0.74)',
  new_main_white_3: 'rgba(0, 0, 0, 0.56)',
  new_main_white_4: 'rgba(236, 239, 241, 0.25)',
  new_topNavBar_1: '#FFFFFF',
};
